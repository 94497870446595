<template>
	<v-container id="crud" fluid tag="section">
		<v-card>
			<v-card-title>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					:label="'Busqueda de ' + title"
					single-line
					hide-details
				></v-text-field>
			</v-card-title>
			<v-data-table
				:headers="headers"
				:items="desserts"
				:search="search"
				sort-by="secret"
				class="elevation-1"
			>
				<template v-slot:top>
					<v-toolbar flat color="white">
						<v-toolbar-title>Administración de {{ title }}</v-toolbar-title>
						<v-divider class="mx-4" inset vertical></v-divider>
						<v-spacer></v-spacer>

						<v-dialog v-model="dialog" max-width="30%" persistent>
							<template v-if="visible" v-slot:activator="{ on, attrs }">
								<v-btn
									:color="$cv('principal')"
									dark
									class="mx-2"
									fab
									v-bind="attrs"
									v-on="on"
									><v-icon blue>mdi-plus</v-icon></v-btn
								>
							</template>
							<v-card>
								<v-form ref="form" v-model="valid" lazy-validation>
									<v-toolbar dark :color="$cv('principal')">
										<v-btn icon dark @click="dialog = false">
											<v-icon>mdi-close</v-icon>
										</v-btn>
										<v-toolbar-title>{{ formTitle }}</v-toolbar-title>
										<v-spacer></v-spacer>
										<v-toolbar-items>
											<v-btn dark text @click="save" :disabled="!valid"
												>Guardar</v-btn
											>
										</v-toolbar-items>
									</v-toolbar>

									<v-card-text>
										<v-container>
											<v-row>
												<v-col cols="12" md="6" sm="6">
													<v-text-field
														v-model="editedItem.ip_address"
														label="IP"
														:rules="rulesIPAddress"
													></v-text-field>
												</v-col>
											</v-row>
											<v-row>
												<v-col cols="12" md="6" sm="6" class="pa-2">
													<v-autocomplete
														:items="itemsSN"
														item-value="id"
														item-text="detalle"
														label="Habilitada"
														v-model="editedItem.whitelisted"
														:rules="rulesRequired"
														return-object
													></v-autocomplete>
												</v-col>
											</v-row>
										</v-container>
									</v-card-text>
								</v-form>
							</v-card>
						</v-dialog>
					</v-toolbar>
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-icon
						v-if="visible"
						small
						class="mr-2"
						:color="$cv('btnEditar')"
						title="Editar"
						@click="editItem(item.id)"
					>
						mdi-pencil
					</v-icon>
					<v-icon
						v-if="visible"
						small
						:color="$cv('btnEliminar')"
						title="Eliminar"
						@click="deleteItem(item.id)"
					>
						mdi-delete
					</v-icon>
					<!--<v-icon
            small
            color="yellow"
            title="Imprimir"
            @click="exportPDF(item.id)"
          >
            mdi-file-document
          </v-icon>-->
				</template>
			</v-data-table>
		</v-card>

		<v-snackbar
			v-model="snackbar"
			:bottom="true"
			:color="color"
			:timeout="timeout"
		>
			{{ text }}

			<template v-slot:action="{ attrs }">
				<v-btn dark text v-bind="attrs" @click="snackbar = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
		<vue-confirm-dialog></vue-confirm-dialog>
	</v-container>
</template>

<script>
	function title() {
		return "Firewall";
	}

	export default {
		data: (vm) => ({
			valid: true,
			nowDate: new Date().toISOString().slice(0, 10),
			_method: "PUT",
			autoGrow: true,
			rows: 1,
			title: title(),
			baseUrl: "/api/firewall",
			menu: false,
			modal: false,
			menu2: false,
			dialog: false,
			snackbar: false,
			visible: true,
			text: "Registro Insertado",
			color: "success",
			timeout: 4000,
			rules: [(v) => v.length <= 100 || "Max 100 caracteres"],
			search: "",
			rulesRequired: [(v) => !!v || "Requerido"],
			rulesIPAddress: [
				(v) => !!v || "Requerido",
				(v) => {
					const pattern =
						/^(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))$/;
					return pattern.test(v) || "Dirección IP inválida.";
				},
			],
			users: false,
			headers: [
				{
					text: "Id",
					align: "start",
					sortable: false,
					value: "id",
				},
				{ text: "IP", filterable: true, value: "ip_address" },
				{ text: "Habilitada", filterable: true, value: "whitelisted" },
				{ text: "Creado", value: "created_at" },
				{ text: "Actualizado", value: "updated_at" },
				{ text: "Accciones", value: "actions", sortable: false },
			],
			itemsSN: [
				{ id: "SI", detalle: "SI" },
				{ id: "NO", detalle: "NO" },
			],
			desserts: [],
			editedIndex: -1,
			editedItem: {
				ip_address: "",
				whitelisted: "",
				id: "",
			},
		}),

		computed: {
			formTitle() {
				return this.editedIndex === -1
					? "Registrar " + this.title
					: "Editar " + this.title;
			},
		},

		watch: {
			dialog(val) {
				val || this.close();
			},
		},

		created() {
			this.initialize();
			//this.getUsers();
		},

		methods: {
			formatDate(date) {
				if (!date) return null;

				const [year, month, day] = date.split("-");
				return `${day}/${month}/${year}`;
			},

			capitalize(s) {
				if (typeof s !== "string") return "";
				return s.charAt(0).toUpperCase() + s.slice(1);
			},

			initialize() {
				axios
					.get(this.baseUrl)
					.then((r) => {
						this.desserts = r.data;
					})
					.catch(function (error) {
						this.snackbar = true;
						this.text = "Error al obtener datos. Error: " + error;
						this.color = "error";
					});
			},

			editItem(id) {
				axios
					.get(this.baseUrl + "/" + id)
					.then((r) => {
						this.editedIndex = r.data.id;
						delete r.data.id;
						this.editedItem = Object.assign({}, r.data);
						this.dialog = true;
					})
					.catch(function (error) {
						this.snackbar = true;
						this.text = "Error al obtener datos. Error: " + error;
						this.color = "error";
					});
			},

			deleteItem(id) {
				this.$confirm({
					message: `Eliminar Registro?`,
					button: {
						no: "Cancelar",
						yes: "Confirmar",
					},

					callback: (confirm) => {
						if (confirm) {
							axios.delete(this.baseUrl + "/" + id).then(
								(response) => {
									this.snackbar = true;
									this.text = "Registro Eliminado";
									this.initialize();
								},
								(error) => {
									this.snackbar = true;
									this.text = "Error al eliminar la planilla Error: " + e.message;
									this.color = "error";
								}
							);
						}
					},
				});
			},

			close() {
				this.dialog = false;
				this.valid = true;
				this.resetValidation();
				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem);
					this.editedIndex = -1;
				});
			},
			resetValidation() {
				this.$refs.form.resetValidation();
			},

			save() {
				if (this.$refs.form.validate()) {
					if (this.editedIndex > -1) {
						let formData = new FormData();
						let url = this.baseUrl + "/" + this.editedIndex;
						formData.append("_method", "PUT");

						axios
							.post(url, {
								_method: "PUT",
								data: this.editedItem,
							})
							.then((r) => {
								if (r.data.status == 200) {
									this.snackbar = true;
									this.text = "Registro Actualizado";
									this.color = "success";
									this.initialize();
								} else {
									this.snackbar = true;
									this.text =
										"Error al actualizar los datos. Error: " + r.data.data;
									this.color = "error";
								}
							})
							.catch((e) => {
								this.snackbar = true;
								this.text = "Error al actualizar los datos. Error: " + e.message;
								this.color = "error";
							});
					} else {
						let method = "post";
						let url = this.baseUrl;

						axios({
							method,
							url,
							data: this.editedItem,
						})
							.then((r) => {
								this.snackbar = true;
								this.text = "Registro Creado";
								this.color = "success";
								this.initialize();
							})
							.catch((e) => {
								console.log(e);
								this.snackbar = true;
								this.text = "Error al ingresar los datos. Error: " + e.message;
								this.color = "error";
							});
					}

					this.close();
				}
			},
			getUsers() {
				axios
					.get("/api/users")
					.then((response) => {
						this.users = response.data;
					})
					.catch((response) => {
						this.snackbar = true;
						this.text = "Error al cargar usuarios. Error: " + e.message;
						this.color = "error";
					});
			},
		},
		mounted() {
			console.log("Componente " + this.baseUrl + " creado");
		},
	};
</script>
